<template>
    <div class="animated fadeIn">
        <CCard>
            <CCardHeader><font-awesome-icon icon="user"/> Users</CCardHeader>
            <CCardBody>
                <CTabs :active-tab="my_tab" @update:activeTab="changeTab">
                    <br>  
                    <CTab>
                        <template slot="title">
                            Active Users/s <CBadge color="success">( {{ [...new Set(users_active.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="users_active"
                                    :fields="users_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    items-per-page-select
                                    :loading="this.table_loading"
                                    border
                                    sorter
                                    pagination
                                >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    <template #role="{item}">
                                        <td>
                                            <CBadge :color="getColorStatus(item.role)">
                                                {{item.role.toUpperCase()}}
                                            </CBadge>
                                        </td>
                                    </template>
                                    <template #Action="{item}">
                                        <td>
                                            <router-link :to="{path: `/${$store.getters['getOrganizationName']}/general-access/user/${item.id}`}">
                                                <CButton 
                                                    color="info" 
                                                    shape="pill" 
                                                    size="sm"
                                                >
                                                    <font-awesome-icon icon="user-cog"/> Manage
                                                </CButton>
                                            </router-link>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                    <CTab>
                        <template slot="title">
                            Inactive User/s <CBadge color="danger">( {{ [...new Set(users_inactive.map((item) => item.name))].length }} )</CBadge>
                        </template>
                        <CRow>
                            <CCol lg="12">
                                <CDataTable
                                    :items="users_inactive"
                                    :fields="users_list_fields"
                                    :table-filter="{ placeholder: 'Your text here.', label: 'Search:'}"
                                    :items-per-page="10"
                                    items-per-page-select
                                    :loading="this.table_loading"
                                    border
                                    sorter
                                    pagination
                                >
                                    <template slot="no-items-view" v-if="this.table_loading">
                                        <center>
                                            <CSpinner
                                                style="width:4rem;height:4rem;"
                                                color="info"
                                            />
                                        </center>
                                    </template>
                                    <template #role="{item}">
                                        <td>
                                            <CBadge :color="getColorStatus(item.role)">
                                                {{item.role.toUpperCase()}}
                                            </CBadge>
                                        </td>
                                    </template>
                                    <template #Action="{item}">
                                        <td>
                                            <template v-if="$store.getters['can']('restore-user')">
                                                <CButton 
                                                
                                                    size="sm"
                                                    shape="pill"
                                                    color="success" 
                                                    :disabled="restoring ? (item.id == restoring_id ? true : false) : false"
                                                    @click="restore(item)"
                                                >
                                                    <font-awesome-icon :icon="restoring ? (item.id == restoring_id ? 'circle-notch' : 'trash-restore') : 'trash-restore'" 
                                                                :pulse="restoring ? (item.id == restoring_id ? true : false) : false"/> Restore
                                                </CButton>

                                            </template>
                                            <template v-else>
                                                <i><b>No permission to restore.</b></i>
                                            </template>
                                        </td>
                                    </template>
                                </CDataTable>
                            </CCol>
                        </CRow>
                    </CTab>
                </CTabs>
            </CCardBody>
        </CCard>   
    </div>
</template>
<script>
export default {
    name: 'UserList',
    data(){
        return {
            users_list: [],
            table_loading: true,
            my_tab: 0,
            restoring: false,
            restoring_id: null,
        }
    },
    created() {
        this.getUsers();
        this.$emit('activeTab', 0);
    },
    computed: {
        
        users_active: function() {
            return this.users_list.filter((item) => item.deleted_at == null);
        },
        users_inactive: function() {
            return this.users_list.filter((item) => item.deleted_at != null);
        },
        users_list_fields: function () {
             let custom_fields= [
                { key: 'employee_number', label: 'Employee Number'},
                { key: 'name', label: 'Name'},
                { key: 'department' },
                { key: 'emails' },
                { key: 'role' },
            ]
            if(this.$store.getters.can('view-user')) {
                custom_fields.push({ key: 'Action', sorter: false})
            }
            return custom_fields;
        }
    },
    methods: {
        restore: function (item) {
            this.restoring = true
            this.restoring_id = item.id;
            this.$Progress.start()
            axios.post('/users/restore/' + item.id, {validateStatus: () => true})
            .then(response => {
                if(response.status == 200) {
                    this.$swal({
                        toast: true,
                        position: 'top-right',
                        showConfirmButton: false,
                        timer: 3000,
                        icon: 'success',
                        title: `${item.name} has been restored.`,
                        showConfirmButton: false,
                        timerProgressBar: true,
                    })
                    this.users_list.filter(obj => obj.id === item.id)[0].deleted_at = null;
                    this.$Progress.finish() 
                }
            })
        },
        changeTab: function (item) {
            this.my_tab = item
        },  
        getUsers: function () {
            this.$Progress.start()
            axios.get('/users/list', {validateStatus: () => true}).then(response => {
                if(response.status == 200) {
                    for (let i = 0; i < response.data.data.length; i++) {
                        let row = {
                                id:  response.data.data[i].id, 
                                employee_number:  response.data.data[i].employee_number, 
                                name: `${response.data.data[i].first_name} ${response.data.data[i].last_name} ${response.data.data[i].suffix ? response.data.data[i].suffix: ''}`, 
                                department: response.data.data[i].department ? response.data.data[i].department.name : 'N/a', 
                                deleted_at: response.data.data[i].deleted_at,
                                role: response.data.data[i].role,
                                created_at: response.data.data[i].created_at,
                                updated_at: response.data.data[i].updated_at,
                                emails: response.data.data[i].emails[0].email
                        }
                        this.users_list.push(row)
                    }
                    this.table_loading = false;
                    this.$Progress.finish()
                }
            })
        },
    }
}
</script>